import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";

const config = {
  apiKey: ESBUILD_VAR__FIREBASE_API_KEY,
  authDomain: "wargamesarena-" + ESBUILD_VAR__ENV +".firebaseapp.com",
};

app = initializeApp(config);

const auth = getAuth(app);

// Maintain an in-memory user object for token refresh
let currentUser = null;

// Subscribe to user state changes
auth.onAuthStateChanged((user) => {
  currentUser = user; // Keep track of the logged-in user
});

export const rawGetJwtToken =
  ({ left, right }) =>
    (emit) =>
       async function () {
          if (currentUser) {
            try {
              // This gets the token or refreshes it if expired
              const token = await currentUser.getIdToken();
              emit(right(token))();
            } catch (e) {
              console.error("Error refreshing token:", e);
              emit(left(e.message))();
            }
          } else {
            emit(left("There is not current user logged in"))();
          }
       };

export const rawLoginWithEmailAndPassword =
  ({ left, right }) =>
    ({ email, password }) =>
      (emit) =>
        // the purs compiler fails on async arrow functions
        async function () {
          try {
            const result = await signInWithEmailAndPassword(auth, email, password);

            const token = await result.user.getIdToken();

            emit(right(token))();
          } catch (e) {
            console.log(e);
            console.log(`Error: ${e.message}`);
            emit(left(e.message))();
          }
        };

export const rawRegisterWithEmailAndPassword =
  ({ left, right }) =>
    ({ username, email, password }) =>
      (emit) =>
        // the purs compiler fails on async arrow functions
        async function () {
          try {
            const result = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );

            await updateProfile(result.user, username);

            const token = await result.user.getIdToken();

            emit(right(token))();
          } catch (e) {
            console.log(e);
            console.log(`Error: ${e.message}`);
            emit(left(e.message))();
          }
        };


export const rawSendPasswordResetEmail =
({ left, right }) =>
  ({ email }) =>
    (emit) =>
      async function () {
        try {
          await sendPasswordResetEmail(auth, email);
          emit(right("Email Sent, all good"))();
        } catch (e) {
          emit(left(e.message))();
        }
      };
